
<template>
  <div class="absolute top-0 left-0 w-full h-full z-[-1] overflow-hidden">
    <div class="w-[150%] h-full bg-primary opacity-0 filter blur-3xl absolute"></div>
  </div>
</template>

<script>
export default {
  name: "BlurBackground"
}
</script>
