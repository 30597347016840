import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Main",
        component: () => import("../layouts/Main.vue"),
        children: [
            {
                path: "/",
                name: "Home",
                component: () => import("../views/Home.vue"),
            },
            {
                path: "/result",
                name: "Result",
                component: () => import("../views/Result.vue"),
            },
            {
                path: '/whois',
                name: 'Whois',
                component: () => import('../views/Whois.vue'),
            },              
            {
                path: '/privacy',
                name: 'Privacy',
                component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
            }
        ],
    },
];

let router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;