
<template>
  <div class="min-h-screen relative">
    <BlurBackground />
    <div class="container mx-auto p-5">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BlurBackground from '../components/BlurBackground.vue'

export default {
  name: "MainLayout",
  components: {
    BlurBackground
  }
}
</script>
