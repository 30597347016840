
<template>
    <MainLayout>
        <RouterView />
    </MainLayout>
</template>

<script>
import MainLayout from './layouts/MainLayout.vue'

export default {
    name: "App",
    components: {
        MainLayout
    },
    data() {
        return {
            msg: "Welcome to Your Vue.js App",
        };
    },
};
</script>
